export default class Scroll {
    static className = 'disable-scroll';
    static disable() {
        if (!document.body.classList.contains(this.className)) {
            document.body.classList.add(this.className);
        }
    }
    static enable() {
        document.body.classList.remove(this.className);
    }
}