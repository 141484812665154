import './styles/base.scss';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'

import Cookies from './scripts/Cookies';
import AOS from 'aos';
import Navbar from './scripts/Navbar';

// Init Hightlight.js
hljs.initHighlightingOnLoad();

Cookies.init();

AOS.init({
    offset: 120,
    delay: 50,
    duration: 500,
    easing: 'ease',
    once: false,
    mirror: false,
    anchorPlacement: 'top-bottom'
});

let navbar = new Navbar('#navbar', {
    breakpoint: 10
});
navbar.init();