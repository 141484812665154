export default class Navbar {
    className = 'is-active';
    isScrolling = false;
    options = {
        breakpoint: 100
    }
    constructor(element, options) {
        if (typeof element === 'object') {
            this.element = element;
        } else if (typeof element === 'string') {
            this.element = document.querySelector(element);
        }
        if (typeof options === 'object') {
            Object.assign(this.options, options);
        }
        this.handleToggle();
    }
    init() {
        window.addEventListener('scroll', () => {
            if (!this.isScrolling) {
                this.isScrolling = true;
                requestAnimationFrame(() => {
                    if (scrollY >= this.options.breakpoint) {
                        this.element.classList.add('navbar-fixed');
                    } else {
                        this.element.classList.remove('navbar-fixed');
                    }
                });
                this.isScrolling = false;
            }
        });
    }
    handleToggle() {
        this.button = document.querySelector('.js-nav-toggle');
        this.navigation = document.querySelector('.js-nav-container');
        this.button.addEventListener('click', e => {
            e.preventDefault();
            this.button.classList.toggle(this.className);
            this.navigation.classList.toggle(this.className);
        });
    }
}