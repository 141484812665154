import JSCookie from 'js-cookie';
import Scroll from './Scroll';

export default class Cookies {
    static className = 'd-none';
    static init() {
        Scroll.disable();
        this.cookiesContainer = document.getElementById('container-cookies');
        this.popupClose = document.getElementById('popup-close');
        
        if (JSCookie.get('cookiesInfo') === "true") {
            Scroll.enable();
            this.cookiesContainer.classList.add(this.className);
        } else {
            this.cookiesContainer.classList.remove(this.className);
        }
        this.handleAccept();
    }
    static handleAccept() {
        this.popupClose.addEventListener('click', () => {
            Scroll.enable();
            this.cookiesContainer.classList.add(this.className);
            JSCookie.set('cookiesInfo', 'true', {
                expires: 31,
                path: ''
            });
            return false;
        });
    }
}